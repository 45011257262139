import { Link } from "gatsby";
import React from "react";
import Button from "../common/buttons/Button";
import SecondaryCard from "../accommodation/cards/SecondaryCard";

const ScheduleVacation = ({ data }) => {
  return (
    <div className="relative block box-border show">
      <div className="max-w-[1140px] px-3 md:px-0 w-full mx-auto">
        <div className="w-full min-h-[90vh] flex flex-wrap flex-col justify-center p-[10px] sm:p-5 box-border">
          <div className="relative block pt-5 pb-[10px]">
            <h1 className="text-3xl sm:text-[50px] leading-[50px] sm:leading-[63px] px-5 font-bold text-[#666666] text-center mb-3 uppercase">
              {data?.heading}
            </h1>
          </div>
          {/* Cards Container */}
          <div className="w-full relative block">
            <div className="relative block pt-5 pb-10">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5 gap-y-5 max-w-full mx-auto">
                {data?.scheduleItem?.map((item, index) => {
                  return <SecondaryCard item={item} key={index} />;
                })}
              </div>
            </div>
          </div>
          {/* Link */}
          <div className="block w-full">
            <div className="w-full pb-5">
              <Link
                to={data?.desLink}
                className="block w-full text-center text-[#006600] text-xl font-bold tracking-[2px]"
              >
                {data?.description}
              </Link>
            </div>
          </div>
          {/* Buttons Container */}
          <div className="w-[95%] mx-auto">
            <div className="flex flex-row flex-wrap max-w-full align-top items-stretch justify-around">
              {/* Button 1 */}
              <div className="w-full sm:w-custom-sm lg:w-custom-lg mb-[10px] flex flex-col flex-wrap justify-start relative">
                <div className="relative block pt-5 pb-10 m-[1px]">
                  <Button
                    btnText={data?.ctaButton1?.title}
                    btnLink={data?.ctaButton1?.link}
                    btnType={data?.ctaButton1?.variant}
                    linkType={data?.ctaButton1?.type}
                    formId={data?.ctaButton1?.formId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleVacation;
