import React from "react";
import PortableText from "react-portable-text";

const VacationExperience = ({ h2Size, pSize, data }) => {
  return (
    <div
      className="relative overflow-hidden box-border"
      style={{
        backgroundImage: `linear-gradient(to bottom,rgba(192, 192, 192, 0.90),rgba(121, 121, 121, 0.90)),url(${data?.sectionBG?.asset.url})`,
        backgroundSize: "initial",
      }}
    >
      <div className="box-border max-w-[1140px] w-full mx-auto md:pb-[100px]">
        <div className="relative flex flex-col flex-wrap justify-center p-[10px] sm:p-5">
          {/* Heading */}
          <div className="w-full md:pt-[100px]">
            <h1 className="text-xl sm:text-[40px] text-white text-center mb-3 font-bold tracking-[2px] uppercase">
              {data?.heading}
            </h1>
          </div>
          <div className="w-full py-2">
            <h2 className="text-sm  sm:text-3xl text-white text-center mb-3 font-bold tracking-[2px] uppercase">
              {data?.subheading}
            </h2>
          </div>
          {/* Content */}
          <div className="relative block w-full pt-3">
            <div className="relative max-w-full w-full flex flex-row flex-wrap justify-around align-top">
              {data?.activityItem.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full sm:w-stay-md lg:w-stay-lg mb-5 flex flex-col flex-wrap justify-start py-5 px-[10px]"
                  >
                    <div className="block text-xl text-center mb-2">
                      <h1
                        className={`${h2Size} uppercase text-white font-bold`}
                      >
                        {item.heading}
                      </h1>
                    </div>

                    <PortableText
                      content={item._rawContent}
                      className={`${pSize} portable-editor text-white font-bold block-content max-w-none text-base`}
                      serializers={{
                        ul: ({ children }) => (
                          <ul className="list-none">{children}</ul>
                        ),
                        li: ({ children }) => (
                          <li className="mx-0 my-1">{children}</li>
                        ),
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacationExperience;
