import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
// import PlainSection from "../components/stopover/PlainSection";
// import BgSection from "../components/stopover/BgSection";
import SliderAndContent from "../components/common/SliderAndContent";
import VacationExperience from "../components/stopover/VacationExperience";
import ScheduleVacation from "../components/stopover/Schedule";
import PanamaResort from "../components/accommodation/panamaresort";
import TranquilobayDifference from "../components/accommodation/how_tranquilobay_different";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import CompaniesGuided from "../components/common/companiesGuided";
import Seo from "../components/seo";

const Stopover = ({ data: { sanityStopoverPage, sanitySitesettings } }) => {
  let seo;
  let hero;
  let breadcrumb;
  let guide;
  let panamaVacation;
  let yourFvt;
  let tranqiulobay;
  let activiy;

  sanityStopoverPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "panamaVacation") {
      panamaVacation = element;
    } else if (element._type === "yourFvt") {
      yourFvt = element;
    } else if (element._type === "tranqiulobay") {
      tranqiulobay = element;
    } else if (element._type === "companyGuided") {
      guide = element;
    } else if (element._type === "activiy") {
      activiy = element;
    }
  });
  return (
    <Layout>
      <Seo data={seo} />
      <Hero
        heroSection={hero}
        h2Size="text-3xl sm:text-[50px] leading-[45px] sm:leading-[60px]"
        h2P="pb-7"
      />
      {sanitySitesettings?.logo && <Logo logo={sanitySitesettings.logo} />}

      {sanityStopoverPage?.contentslider?.map((item, index) => (
        <SliderAndContent
          data={item}
          key={index}
          sliderId={`stopover-${index}`}
        />
      ))}

      {activiy && <VacationExperience data={activiy} />}
      {panamaVacation && <ScheduleVacation data={panamaVacation} />}
      {yourFvt && (
        <PanamaResort
          data={yourFvt}
          color="text-white"
          minH="min-h-[80vh] sm:min-h-[120vh]"
          bgUrl={`linear-gradient(to bottom,rgba(146, 146, 146, 0.90),rgba(192, 192, 192, 0.90)),url(${yourFvt.sectionBG.asset.url})`}
          bgSize="bg-cover bg-repeat-x"
        />
      )}
      {tranqiulobay && <TranquilobayDifference data={tranqiulobay} />}
      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      <Instagram />
      {guide && (
        <div>
          <CompaniesGuided data={guide} />
          <Logo logo={sanitySitesettings?.logo} />
        </div>
      )}
    </Layout>
  );
};

export default Stopover;

export const query = graphql`
  query {
    sanityStopoverPage {
      pageBuilder {
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          ctaButton {
            link
            title
            type
            variant
            formId
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanityActiviy {
          _type
          heading
          subheading
          sectionBG {
            asset {
              url
            }
          }
          activityItem {
            _rawContent
            heading
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }

        ... on SanityPanamaVacation {
          _type
          desLink
          description
          heading
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          scheduleItem {
            heading
            number
            subHeading
            svg
            itemImg {
              alt
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  placeholder: BLURRED
                  formats: WEBP
                )
              }
            }
          }
        }
        ... on SanityYourFvt {
          _type
          sectionBG {
            asset {
              url
            }
          }
          heading
          txt
          youtubeChannelId
          coverImg {
            alt
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
            }
          }
          vidoeItems {
            title
            videoId
          }
        }
        ... on SanityTranqiulobay {
          _type
          _rawContent
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
          }
          sliderItems {
            title
            content
            ctaButton1 {
              link
              title
              type
              variant
              formId
            }
            slideBg {
              asset {
                url
              }
            }
          }
          title
        }
        ... on SanityCompanyGuided {
          _type
          heading
          sectionBg {
            asset {
              url
            }
          }
          list {
            name
          }
        }
      }
      contentslider {
        title
        bgRepeat
        bgSize
        direction
        height
        overlay
        size
        textColor
        paragraphTitle
        sectionBG {
          asset {
            url
          }
        }
        ctaButton1 {
          link
          title
          type
          variant
          formId
        }
        ctaButton2 {
          link
          title
          type
          variant
          formId
        }
        ctaButton3 {
          link
          title
          type
          variant
          formId
        }
        sliderImages {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
          }
        }
        _rawContent
        _rawContent2
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
